/* global LoginPage, axios, gapi */
/* eslint-disable no-console */
class LoginComponentB2C extends LoginPage {

    data() {
        let superdata = super.data();
        superdata.validationMsg = false;
        return superdata;
    }

    getWatch(){
        return {
            "$store.getters.getIsLogin": 'closeModal',
        };
    }

    getComputed(){
        return {
            disableLoginAction: function () {
                return this.$store.getters.getSetting.DisableLoginAction;
            },
            disableRegisterAction: function () {
                return this.$store.getters.getSetting.DisableRegisterAction;
            }
        };
    }

    clear() {
        this.username = '';
        this.password = '';
    }

    async login() {
        let loginResult = await this.$store.dispatch('login', {
            username: this.username,
            password: this.password
        });
        if (!loginResult.ok) {
            if (loginResult.userNotActive) {
                this.validationMsg = true;
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Account Validation'),
                    message: this.tr("Please validate your mail account"),
                    type: 'error'
                });
            } else {
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Login'),
                    message: this.tr("Incorrect Username or Password"),
                    type: 'error'
                });
            }
        } else
            this.afterLoginRedirect();
    }

    afterLoginRedirect() {
        if (this.$store.state.mustUpdateInfo === true)
            this.router.push('/mustUpdateInfo');
        if (this.$store.getters.getIsLogin) {
            this.clear();
            if (!this.$store.state.redirectAfterLogin) {
                //this.$store.commit( 'updateItemsIsLoaded', false );
                this.$router.push(this.$store.state.afterLoginPath);
            } else {
                let redirectPath = this.$store.state.redirectAfterLogin;
                this.$store.dispatch('setRedirectLogin', null);
                this.$store.dispatch('activePreserveCar', false);
                this.$router.push(redirectPath);
            }
            //this.$router.push('indicators')
        } else {
            this.$store.dispatch('showNotificacion', {
                title: this.tr('Login'),
                message: this.tr("Incorrect Username or Password"),
                type: 'error'
            });
        }
    }

    async checkUser() {
        if (this.$store.getters.getSetting.OnlyEmailUsers) {
            const mailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!mailReg.test(this.username)) {
                this.alertError = ['FORMATVALUEERR', "Email"];
                return;
            }
        }
        let check = await axios.get('/ecommerce/api/checkuser/' + this.username);
        this.existsUser = check.data;
        if(!this.existsUser) {
            let prospectPerson = await axios.post('/ecommerce/api/newProspectPerson/' + this.username);
            if(prospectPerson) this.person.Code = prospectPerson.Code; 
            this.person.WebUser = this.username;
            this.person.Email = this.username;
            this.showRegister = true;
        }
    }

    closeModal() {
        if (this.$store.getters.getIsLogin) {
            this.clear();
        } else {
            this.$store.dispatch('showNotificacion', {
                title: this.tr('Login'),
                message: this.tr("Incorrect Username or Password"),
                type: 'error'
            });
        }

    }

    onFBLoad() {
        if (window.FB) {
            // In your onload method:
            window.FB.api('/me', {fields: 'last_name'}, function (response) {
                console.log(response);
            });
            window.FB.Event.subscribe('auth.login', this.login_event);
            window.FB.Event.subscribe('auth.logout', this.logout_event);
            window.FB.getLoginStatus(function (response) {
                console.log("FACEBOOK Call Back", response);
            });
        }
    }

    onLoadGoogle() {
        let self = this;
        if (window.gapi) {
            gapi.load('auth2', function () {
                // Retrieve the singleton for the GoogleAuth library and set up the client.
                window.auth2 = gapi.auth2.init({client_id: '411860170731-ocuredme9dg0khkmc8pmudfv94hfrhp2.apps.googleusercontent.com'})
                    .then(function () {
                        let a = gapi.auth2.getAuthInstance();
                        console.log(a);
                        window.gapi.signin2.render("google_button");
                        if (a.isSignedIn.get()) {
                            self.onSignIn(a.currentUser.get());
                            $('#google_button').hide();
                        } else {
                            gapi.signin2.render('google_button', {onsuccess: self.onSignIn});
                            $('#google_button').show();
                            window.show_google_message = true;
                        }

                    });
            });
        }
    }

    async onSignIn(googleUser) {
        let id_token = googleUser.getAuthResponse().id_token;
        axios.post('/ecommerce/api/google_login', {
            id_token: id_token,
            deviceId: localStorage.getItem("deviceId")
        })
            .then(async (result) => {
                if (result.data.ok) {
                    await this.$store.dispatch('loadSession');
                    this.afterLoginRedirect();
                }
            });
    }

    login_event(response) {
        console.log("login_event");
        console.log(response.status);
        console.log(response);
    }

    logout_event(response) {
        // eslint-disable-next-line no-console
        console.log("logout_event");
        // eslint-disable-next-line no-console
        console.log(response.status);
        // eslint-disable-next-line no-console
        console.log(response);
    }

    getMethods() {
        let superMethods = super.getMethods();
        superMethods.clear = this.clear;
        superMethods.login = this.login;
        superMethods.afterLoginRedirect = this.afterLoginRedirect;
        superMethods.closeModal = this.closeModal;
        superMethods.onFBLoad = this.onFBLoad;
        superMethods.onLoadGoogle = this.onLoadGoogle;
        superMethods.onSignIn = this.onSignIn;
        superMethods.login_event = this.login_event;
        superMethods.logout_event = this.logout_event;
        return superMethods;
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container-fluid">
                      <div class="section-main">
                        <section class="padding-small">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="container">
                                <div class="row justify-content-center">
                                  <div class="col-lg-8 text-center align-self-center mt-5" v-if="disableLoginAction">
                                        <div class="block">
                                            <div class="block-header">
                                                <div class="text-center"><i class="fas fa-exclamation-triangle fa-3x mb-3" style="color: #666;"></i></div>
                                                <h5 class="text-center mb-5">{{tr("LOGINNOTAVAILABLE")}}.</h5>
                                                <router-link to="/" class="btn btn-custom">{{tr("Go to Homepage")}}</router-link>
                                            </div>
                                        </div>
                                  </div>
                                  <div class="col-lg-8" v-else>
                                    <div class="block">
                                      <div class="block-header">
                                        <h5 class="text-center">{{tr('Login')}}</h5>
                                      </div>
                                      <div class="block-body login-form">
                                        <hr>
                                        <form aria-autocomplete="none" autocomplete="off">
                                            <div class="col-sm-12" v-if="alertError.length>0">
                                                <div ref='errorRegisterFrom' class="alert alert-danger alert-dismissible fade show" role="alert" >
                                                    <strong>{{tr(alertError)}}!</strong>
                                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="alertError=[]">
                                                    <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-if="!existsUser">
                                                <div v-if="!showRegister">
                                                    <div class="form-group">
                                                        <input :placeholder="tr('Email')" v-model="username" name="username" type="text" class="form-control validate" aria-autocomplete="none" autocomplete="none">
                                                    </div>                                                    
                                                    <button class="center-align btn btn-secondary" @click.prevent="checkUser">{{tr('Continue')}}</button>
                                                </div>
                                                <div v-else>
                                                    <PersonGenericComponent :confirmPassword="true" :personObj="person" />
                                                    <div class="form-group" >
                                                        <button class="btn btn-custom btn-custom-login mr-3  float-left" @click="clearForm()">{{tr('LoginText')}}</button>
                                                        <button class="btn btn-secondary btn-custom-register float-right"  @click="emitEvent('person-save')">{{tr('Sign Up')}}</button>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div v-else class="col s6 offset-s3 center-align">
                                                <div class="form-group">
                                                    <input :placeholder="tr('Email')" v-model="username" name="username" type="text" class="form-control validate" aria-autocomplete="none" autocomplete="none">
                                                </div>
                                                <div class="form-group">
                                                    <input :placeholder="tr('Password')" v-model="password"  id="password" name="password" type="password" class="form-control validate" aria-autocomplete="none" autocomplete="new-password" >
                                                </div>
                                                <p class="">
                                                    <input name="redirect_on_success" class="form-control" type="hidden" value="/ecommerce/#/">
                                                </p>
                                                <div class="alert alert-warning" role="alert" v-if="validationMsg">
                                                    {{tr("You must validate your account to continue, check your mailbox and activate your account by visiting the link sent")}}.
                                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div class="form-group text-right">
                                                    <router-link  to="/home" class="btn btn-light" role="button">{{tr('Close')}}</router-link>
                                                    <button class="center-align btn btn-secondary" @click.prevent="login">{{tr('Login')}}</button>
                                                </div>
                                                <div class="row">
                                                    <div id="google_button" class="col-6"></div>
                                                    <div id="facebook_button" class="col-6 d-none">
                                                        <div class="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="text-center">
                                                    <template v-if="disableRegisterAction">
                                                        <div class="block text-center align-self-center" >
                                                            <div class="block">
                                                                 <div class="block-header">
                                                                   <h5 class="text-center">{{tr('New account')}}</h5>
                                                               </div>
                                                                <div class="block-Body">
                                                                    <div class="text-center"><i class="fas fa-exclamation-triangle fa-3x mb-3" style="color: #666;"></i></div>
                                                                    <h5 class="text-center mb-5">{{tr("REGISTERNOTAVAILABLE")}}.</h5>
                                                                    <router-link to="/" class="btn btn-custom">{{tr("Go to Homepage")}}</router-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <span>{{tr('Not yet our customer?')}} </span>
                                                        <router-link  to="/signup" class="btn btn-link" role="button">{{tr('Register')}}</router-link>
                                                        <router-link  to="/recovery_password" class="btn btn-link" role="button">{{tr('Forgot your password?')}}</router-link>
                                                    </template>
                                                </div>
                                            </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </template>
                          <template v-if="$store.getters.getAppState=='onload'">
                              <div class="loading">
                                  <i class="fas fa-spinner fa-spin fa-3x"></i>
                              </div>
                          </template>
                        </section>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

LoginComponentB2C.registerComponent();
